import React, { Fragment, useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";

import PortalFooter from "./PortalFooter";
import PortalHeader from "./PortalHeader";
import { useInit } from "../hooks/useInit";
import { Outlet } from "./router/Outlet";
import { AppMenu } from "./AppMenu";
import { useNavigate, useLocation } from "react-router-dom";
import PortalSnackbar from "./PortalSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { APP_URL, NO_AUTH_URLS } from "./utils/url";
import Instructions from "./Instructions";

const useStyles = makeStyles({
  bodyStyle: {
    width: "1140px",
    margin: "0px auto",
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    minHeight: "100vh",
    position: "relative",
    overflow: "hidden",
  },
  "@media print": {
    bodyStyle: {
      overflow: "hidden",
      display: "inline-block",
      margin: "25px",
    },
    instructionRow: {
      display: "none",
    },
    menubar: {
      display: "none",
    },
  },
});

export const App = () => {
  const loggedIn = useInit();
  const classes = useStyles();
  const printRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { initialized } = useSelector((state) => state.session);
  useEffect(() => {
    if (!loggedIn && initialized && !NO_AUTH_URLS.some((item) => location.pathname.startsWith(item))) {
      navigate(APP_URL.AUTH);
    }
  }, [loggedIn, initialized]);

  const childProps = { printRef };

  return (
    <Fragment>
      <div className={classes.bodyStyle} ref={printRef}>
        <PortalHeader />
        <Instructions />
        {loggedIn && <AppMenu />}
        <Outlet {...childProps} />
        <PortalFooter />
        <PortalSnackbar />
      </div>
    </Fragment>
  );
};

import React from "react";
import { useSelector } from "react-redux";

export default function PortalFooter() {
  const footer = useSelector((state) => state.config.config.footer);

  return (
    <div className="font-bold footerContainer mt-3">
      <p>
        &copy; {new Date().getFullYear()} — {footer.footerText}
      </p>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import api from "../lib/axios";
import { API_URL } from "../components/utils/url";
import { setCaseDropdownData } from "../store/features/caseDropdownSlice";

export const useNewCaseOptions = () => {
  const [dropDownData, setDropDownData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const newCaseOptionsRes = await api.get(API_URL.GET_CASE_DROPDOWN_OPTIONS);
    if (newCaseOptionsRes) {
      setDropDownData(newCaseOptionsRes.data);
      dispatch(setCaseDropdownData(newCaseOptionsRes.data));
    }
  };

  return dropDownData;
};

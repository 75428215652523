import moment from "moment";
import { formatPhone } from "../lib/misc";
import { pregnantTypes, treatmentTypes } from "../components/DiseaseInformation";

const setTreatmentsData = (treatments) => {
  return treatments.map((treatment) => {
    return {
      treatment_id: treatment.treatment,
      treatment_start_date: moment(treatment.treatment_start_date).format("YYYY-MM-DD") || "",
      treatment_end_date: moment(treatment.treatment_end_date).format("YYYY-MM-DD") || "",
    };
  });
};

const setLabTestResultsData = (results) => {
  return results.map((result) => {
    return {
      test_type_id: result.test_type,
      test_result_id: result.test_result,
      result_value: result.result_value,
    };
  });
};

export const constructFormValue = (caseInfo) => {
  return {
    notes: caseInfo.notes,
    treatmentAllowed: caseInfo?.event?.condition?.is_treatment_allowed,
    noLabReported: caseInfo?.lab_test?.lab?.name === "No Lab Reported" ? true : false,
    facility_id: caseInfo?.facility || null,
    facility: {
      address: {
        street: caseInfo?.facility?.address?.street || "",
        city: caseInfo?.facility?.address?.city || "",
        zip: caseInfo?.facility?.address?.zip || "",
        unit: caseInfo?.facility?.address?.street || "",
        county_id: caseInfo?.facility?.address?.county || null,
        state_id: caseInfo?.facility?.address?.state || null,
      },
      name: caseInfo?.facility?.id,
    },
    clinician: {
      first_name: caseInfo?.clinician?.first_name || "",
      last_name: caseInfo?.clinician?.last_name || "",
      area_code: caseInfo?.clinician?.area_code || "",
      phone_number: caseInfo?.clinician.phone_number || "",
      phone: formatPhone(caseInfo?.clinician?.area_code, caseInfo?.clinician?.phone_number) || "",
    },
    patient: {
      first_name: caseInfo?.patient?.first_name || "",
      last_name: caseInfo?.patient?.last_name || "",
      middle_name: caseInfo?.patient?.middle_name || "",
      dob: moment(caseInfo?.patient?.dob).format("YYYY-MM-DD") || "",
      telephone_type_id: caseInfo?.patient?.telephone_type || null,
      email: caseInfo?.patient?.email || "",
      phone_number: caseInfo?.patient?.phone_number || "",
      area_code: caseInfo?.patient?.area_code || "",
      phone: formatPhone(caseInfo?.patient?.area_code, caseInfo?.patient?.phone_number) || "",
      gender_id: caseInfo?.patient?.gender || null,
      sex_id: caseInfo?.patient?.sex || null,
      ethnicity_id: caseInfo?.patient?.ethnicity || null,
      address: {
        street: caseInfo?.patient?.address?.street || "",
        unit: caseInfo?.patient?.address?.unit || "",
        city: caseInfo?.patient?.address?.city || "",
        zip: caseInfo?.patient?.address?.zip || "",
        state_id: caseInfo?.patient?.address?.state || null,
        county_id: caseInfo?.patient?.address?.county || null,
      },
    },
    order: {
      facility_id: caseInfo.order?.facility || null,
      facility: {
        name: caseInfo.order?.facility?.name || "",
        address: {
          street: caseInfo.order?.facility?.address?.street || "",
          unit: caseInfo.order?.facility?.address?.unit || "",
          city: caseInfo.order?.facility?.address?.city || "",
          zip: caseInfo.order?.facility?.address?.zip || "",
          state_id: caseInfo.order?.facility?.address?.state || null,
          county_id: caseInfo.order?.facility?.address?.county || null,
          area_code: caseInfo.order?.facility?.area_code || "",
          phone_number: caseInfo.order?.facility?.phone_number || "",
        },
      },
      clinician_first_name: caseInfo.order?.clinician_first_name || "",
      clinician_last_name: caseInfo.order?.clinician_last_name || "",
      specimen_source_id: caseInfo.order?.specimen_source || null,
      specimen_collection_date: moment(caseInfo.order?.specimen_collection_date).format("YYYY-MM-DD") || null,
    },
    event: {
      condition_id: caseInfo?.event?.condition || null,
      facility_visit_type_id: caseInfo?.event?.facility_visit_type || null,
      admitted_date: moment(caseInfo?.event?.admitted_date).format("YYYY-MM-DD") || "",
      discharged_date: moment(caseInfo?.event?.discharged_date).format("YYYY-MM-DD") || "",
      pregnant: caseInfo?.event?.pregnant ? pregnantTypes.find((type) => type.name === "Yes") : pregnantTypes.find((type) => type.name === "No"),
      treatment_status:
        caseInfo?.event?.treatments.length > 0
          ? treatmentTypes.find((type) => type.name === "Yes")
          : treatmentTypes.find((type) => type.name === "No"),
      treatments: caseInfo?.event?.treatments?.length > 0 ? setTreatmentsData(caseInfo?.event?.treatments) : [],
    },
    lab_test: {
      lab: {
        name: caseInfo?.lab_test?.lab?.name || "",
        address: {
          street: caseInfo?.lab_test?.lab?.address?.street || "",
          city: caseInfo?.lab_test?.lab?.address?.city || "",
          zip: caseInfo?.lab_test?.lab?.address?.zip || "",
          unit: caseInfo?.lab_test?.lab?.address?.street || "",
          county_id: caseInfo?.lab_test?.lab?.address?.county || null,
          state_id: caseInfo?.lab_test?.lab?.address?.state || null,
        },
        area_code: caseInfo?.lab_test?.lab?.area_code || "",
        phone_number: caseInfo?.lab_test?.lab?.phone_number || "",
        phone: formatPhone(caseInfo?.lab_test?.lab?.area_code, caseInfo?.lab_test?.lab?.phone_number),
      },
      accession_number: caseInfo?.lab_test?.accession_number || "",
      test_date: moment(caseInfo?.lab_test?.test_date).format("YYYY-MM-DD"),
      lab_test_results: caseInfo?.lab_test_results?.length > 0 ? setLabTestResultsData(caseInfo?.lab_test_results) : [],
    },
  };
};

export const formDefaultValue = {
  notes: "",
  treatmentAllowed: false,
  noLabReported: false,
  facility_id: null,
  facility: {
    address: {
      street: "",
      city: "",
      zip: "",
      unit: "",
      county_id: null,
      state_id: null,
    },
    name: "",
  },
  clinician: {
    first_name: "",
    last_name: "",
    area_code: "",
    phone_number: "",
  },
  patient: {
    first_name: "",
    last_name: "",
    middle_name: "",
    dob: "",
    telephone_type_id: null,
    email: "",
    phone_number: "",
    area_code: "",
    phone: "",
    gender_id: null,
    sex_id: null,
    races: [],
    ethnicity_id: null,
    address: {
      street: "",
      unit: "",
      city: "",
      zip: "",
      state_id: null,
      county_id: null,
    },
  },
  order: {
    facility_id: null,
    facility: {
      name: null,
      address: {
        street: "",
        unit: "",
        city: "",
        zip: "",
        state_id: null,
        county_id: null,
      },
      area_code: "",
      phone_number: "",
      phone: "",
    },
    clinician_first_name: "",
    clinician_last_name: "",
    specimen_source_id: null,
    specimen_collection_date: null,
  },
  event: {
    condition_id: null,
    facility_visit_type_id: null,
    admitted_date: "",
    discharged_date: "",
    pregnant: "",
    treatment_status: "",
    treatments: [
      {
        treatment_id: null,
        treatment_start_date: null,
        treatment_end_date: null,
      },
    ],
  },
  lab_test: {
    lab: {
      name: null,
      address: {
        street: "",
        city: "",
        zip: "",
        unit: "",
        county_id: null,
        state_id: null,
      },
      area_code: "",
      phone_number: "",
      phone: "",
    },
    accession_number: "",
    test_date: "",
    lab_test_results: [
      {
        test_type_id: null,
        test_result_id: null,
        result_value: "",
      },
    ],
  },
};

import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { pathname } from "../lib/misc";
import { useSelector } from "react-redux";
import { useState } from "react";

const useStyles = makeStyles({
  banner: {
    height: "100px",
    padding: "0px !important",
    width: "100% !important",
  },
});

export default function PortalHeader() {
  const [headerPresent, setHeaderPresent] = useState(true);
  const header = useSelector((state) => state.config.config.header);
  const { config } = useSelector((state) => state.config);
  const classes = useStyles();

  const state = process.env.DEFAULT_STATE;
  const relativeUrl = process.env.RELATIVE_URI;

  return (
    <>
      {headerPresent && (
        <div className="p-3">
          <img
            className={classes.banner}
            src={`${relativeUrl}assets/${header.headerImage}`}
            alt={`${state} header`}
            onError={(err) => {
              console.log(err);
              setHeaderPresent(false);
            }}
          />
        </div>
      )}
      {config.header.headerTitle && <p className="header-title">{config.header.headerTitle}</p>}
    </>
  );
}

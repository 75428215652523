export const setDefaultOrderAndLab = (setValue, noLab, no_lab_data = {}) => {
  if (!noLab) {
    const orderDefault = {
      facility: {
        id: null,
        name: null,
        address: {
          street: null,
          unit: null,
          city: null,
          zip: null,
          state_id: null,
          county_id: null,
        },
        area_code: null,
        phone_number: null,
      },
      clinician_first_name: null,
      clinician_last_name: null,
      specimen_source_id: null,
      specimen_collection_date: null,
    };

    const labDefault = {
      lab: {
        name: null,
        address: {
          // You may need to set default values for address fields as well
          street: null,
          unit: null,
          city: null,
          zip: null,
          state_id: null,
          county_id: null,
        },
        area_code: null,
        phone_number: null,
      },
      accession_number: null,
      test_date: null,
      lab_test_results: [
        {
          test_type_id: null,
          test_result_id: null,
          result_value: null,
        },
      ],
    };
    setValue("lab_test", labDefault);
    setValue("order", orderDefault);
  } else {
    const noReportOrderValue = {
      facility: {
        id: no_lab_data.facility.id,
        name: no_lab_data.facility.name,
        address: {
          street: no_lab_data.facility.address.street,
          unit: no_lab_data.facility.address.unit,
          city: no_lab_data.facility.address.city,
          zip: no_lab_data.facility.address.zip,
          state_id: no_lab_data.facility.address.state,
          county_id: no_lab_data.facility.address.county,
        },
        area_code: no_lab_data.facility.area_code,
        phone_number: no_lab_data.facility.phone_number,
      },
      clinician_first_name: "",
      clinician_last_name: "",
      specimen_source_id: no_lab_data.specimen_source,
      specimen_collection_date: new Date().toISOString().split("T")[0],
    };

    const noReportLabValue = {
      lab: {
        name: no_lab_data.lab.name,
        address: {
          street: no_lab_data.lab.address.street,
          unit: no_lab_data.lab.address.unit,
          city: no_lab_data.lab.address.city,
          zip: no_lab_data.lab.address.zip,
          state_id: no_lab_data.lab.address.state,
          county_id: no_lab_data.lab.address.county,
        },
        area_code: no_lab_data.lab.area_code,
        phone_number: no_lab_data.lab.phone_number,
      },
      accession_number: null,
      test_date: new Date().toISOString().split("T")[0],
      lab_test_results: [
        {
          test_type_id: no_lab_data.lab_test_result.test_type,
          test_result_id: no_lab_data.lab_test_result.test_result,
          result_value: no_lab_data.lab_test_result.result_value,
        },
      ],
    };

    setValue("lab_test", noReportLabValue);
    setValue("order", noReportOrderValue);
  }
};

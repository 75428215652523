import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RHFMultiSelect from "./RHFMultiSelect";

import { useDispatch, useSelector } from "react-redux";
import {
  setNotifiable,
  setTestTypes,
  setTreatmentAllowed,
  setTreatments,
  setLabReportRequired,
  setNoLabChecked,
  setLabExpanded,
  setOrderExpanded,
  setTreatmentCount,
  setConditionSpecimens,
} from "../store/features/conditionSlice";
import { AddBox, Delete } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import { setDefaultOrderAndLab } from "../helpers/noLabHelper";

export const pregnantTypes = [
  { id: 1, name: "Yes", value: true },
  { id: 2, name: "No", value: false },
];

export const treatmentTypes = [
  { id: 1, name: "Yes", value: true },
  { id: 2, name: "No", value: false },
  { id: 3, name: "Unknown", value: false },
];

export default function DiseaseInformation(props) {
  const [pregnantDisabled, setPregnantDisabled] = useState(false);
  const dispatch = useDispatch();
  const {
    conditions,
    facility_visit_types,
    condition_treatments,
    condition_specimen_sources,
    condition_test_types,
    treatments,
    test_types,
    specimen_sources,
  } = useSelector((state) => state.caseDropdown.caseDropdown);
  const { notifiable, conditionTreatments, treatmentAllowed, treatment, treatmentCount } = useSelector((state) => state.condition);
  const [condition, setCondition] = useState(null);
  console.log(treatment, treatmentAllowed);
  const treatmentFields = [];
  const treatment_status = props.watch("event.treatment_status");

  useEffect(() => {
    if (!treatment_status?.value) {
      props.clearErrors("event.treatments");
      dispatch(setTreatmentCount(1));
    }
  }, [treatment_status]);

  useEffect(() => {
    const sex = props.watch("patient.sex_id");
    if (sex && sex?.name === "Male") {
      props.setValue("event.pregnant", null);
      setPregnantDisabled(true);
    } else {
      setPregnantDisabled(false);
    }
  }, [props.watch("patient.sex_id")]);

  useEffect(() => {
    if (!props.caseId) {
      const eventData = {
        condition_id: null,
        facility_visit_type_id: null,
        admitted_date: "",
        discharged_date: "",
        pregnant: "",
        treatment_status: "",
        treatments: [],
      };
      props.setValue("event", eventData);
    }
  }, []);

  for (let ix = 0; ix < treatmentCount; ix++) {
    console.log(`event.treatments[${ix}]?.treatment_start_date `);
    console.log(treatmentAllowed, treatment);
    treatmentFields.push(
      <Grid className="pt-3" container spacing={2} key={`treatment-number-${ix}`}>
        <Grid item xs={5}>
          <RHFMultiSelect
            name={`event.treatments[${ix}.treatment_id]`}
            fullWidth
            register={props.register}
            readOnly={props.readonly}
            addBlankOption={true}
            label={"Treatment Name " + (treatmentAllowed ? "*" : "")}
            control={props.control}
            options={conditionTreatments}
            error={!!props.errors?.event?.treatments?.[ix]?.treatment_id}
            helperText={props.errors?.event?.treatments?.[ix]?.treatment_id && props.errors?.event?.treatments?.[ix]?.treatment_id?.message}
            objGOS={true}
            disabled={((!treatmentAllowed || !treatment) && !props.caseId) || (props.caseId && !treatment)}
            inputProps={{ "data-testid": `event.treatments[${ix}]?.treatment_id` }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className="w-100"
            variant="standard"
            name={`event.treatments[${ix}].treatment_start_date`}
            type="date"
            pattern="\d{4}-\d{2}-\d{2}"
            InputLabelProps={{ shrink: true }}
            inputProps={{ "data-testid": "event.treatment_start_date", readOnly: props.readonly, max: "9999-12-31" }}
            label="Treatment Start Date"
            disabled={((!treatmentAllowed || !treatment) && !props.caseId) || (props.caseId && !treatment)}
            error={!!props.errors.event?.treatments?.[ix]?.treatment_start_date}
            helperText={
              props.errors.event?.treatments?.[ix]?.treatment_start_date && props.errors.event.treatments?.[ix]?.treatment_start_date.message
            }
            {...props.register(`event.treatments[${ix}].treatment_start_date`)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className="w-100"
            variant="standard"
            name={`event.treatments[${ix}].treatment_end_date`}
            type="date"
            pattern="\d{4}-\d{2}-\d{2}"
            InputLabelProps={{ shrink: true }}
            inputProps={{ "data-testid": "event.treatment_end_date", readOnly: props.readonly, max: "9999-12-31" }}
            label="Treatment Stop Date"
            disabled={((!treatmentAllowed || !treatment) && !props.caseId) || (props.caseId && !treatment)}
            error={!!props.errors.event?.treatments?.[ix]?.treatment_end_date}
            helperText={props.errors.event?.treatments?.[ix]?.treatment_end_date && props.errors.event.treatments?.[ix]?.treatment_end_date.message}
            {...props.register(`event.treatments[${ix}].treatment_end_date`)}
          />
        </Grid>
        {ix > 0 && !props.caseId ? (
          <Grid item xs={2}>
            <IconButton onClick={(e) => deleteTreatment(ix)} size="large">
              <Delete />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    );
  }

  const deleteTreatment = (ix) => {
    dispatch(setTreatmentCount(treatmentCount - 1));
    const values = props.getValues();
    const treatments = values.event.treatments;
    treatments.splice(ix, 1);
    props.setValue("event.treatments", treatments);
  };

  const resetTreatments = (treatmentAllowed) => {
    dispatch(setTreatmentCount(1));
    props.setValue("event.treatment_status", null, { shouldValidate: true });
    props.setValue("event.treatments", [], { shouldValidate: true });
  };

  const conditionChange = async (e, condition) => {
    // Reset no lab report required checkbox
    dispatch(setNoLabChecked(false));
    dispatch(setOrderExpanded(true));
    dispatch(setLabExpanded(true));
    setDefaultOrderAndLab(props.setValue, false);
    if (!condition) {
      dispatch(setNotifiable(false));
      dispatch(setTreatments([]));
      dispatch(setTestTypes([]));
      return;
    }
    console.log(props.getValues());
    props.setValue("event.condition_id", condition);
    props.setValue("event.treatment_id", "");
    setCondition(condition);
    console.log(condition);
    console.log(condition.is_treatment_allowed);
    props.setValue("treatmentAllowed", condition.is_treatment_allowed);
    resetTreatments();
    condition.is_notifiable ? dispatch(setNotifiable(true)) : dispatch(setNotifiable(false));
    condition.is_treatment_allowed ? dispatch(setTreatmentAllowed(true)) : dispatch(setTreatmentAllowed(false));
    condition.is_lab_report_required ? dispatch(setLabReportRequired(true)) : dispatch(setLabReportRequired(false));
    let treatmentsByCondition = [];
    let testTypesByCondition = [];
    let specimenSourcesByCondition = [];
    condition_treatments.map((element) => {
      if (condition.id === element.condition_id) {
        treatmentsByCondition.push(treatments.find((treatment) => treatment.id === element.treatment_id));
      }
    });
    console.log(treatmentsByCondition);
    condition_test_types.map((element) => {
      if (condition.id === element.condition_id) {
        testTypesByCondition.push(test_types.find((testType) => testType.id === element.test_type_id));
      }
    });
    condition_specimen_sources.map((element) => {
      if (condition.id === element.condition_id) {
        specimenSourcesByCondition.push(specimen_sources.find((specimen) => specimen.id === element.specimen_source_id));
      }
    });
    console.log(treatmentsByCondition, testTypesByCondition);
    dispatch(setTreatments(treatmentsByCondition));
    dispatch(setTestTypes(testTypesByCondition));
    dispatch(setConditionSpecimens(specimenSourcesByCondition));
    props.clearErrors("event.treatment_status");
    props.clearErrors("event.treatments");
  };

  const addTreatment = () => {
    dispatch(setTreatmentCount(treatmentCount + 1));
  };

  const treatmentStatusChange = (e, status) => {
    if (status.value) {
      props.setValue("event.treatmentStatus", true);
    } else {
      props.setValue("event.treatmentStatus", false);
    }
    console.log(props.getValues());
    props.setValue("event.treatment_status", status);
    props.clearErrors("event.treatments");
  };

  const treatmentStatus = (
    <>
      <RHFMultiSelect
        name="event.treatment_status"
        fullWidth
        register={props.register}
        readOnly={props.readonly}
        addBlankOption={true}
        label="Treatment"
        disabled={!treatmentAllowed}
        control={props.control}
        options={treatmentTypes}
        onChange={treatmentStatusChange}
        error={!!props.errors.event?.treatment_status}
        helperText={props.errors.event?.treatment_status && props.errors.event.treatment_status.message}
        objGOS={true}
        inputProps={{ "data-testid": "event.treatment_status" }}
      />
    </>
  );
  return (
    <Card className="mt-3" variant="outlined">
      <CardContent>
        <div className="flex justify-between align-start">
          <p className="m-0 card-header">ENCOUNTER INFORMATION</p>
          <div className="notifiable">
            <Box sx={{ display: "flex" }}>
              <Grow in={notifiable} style={{ transformOrigin: "0 0 0" }} {...(notifiable ? { timeout: 1000 } : {})}>
                <p className="m-0">
                  Immediate reporting by phone is required within 4 hours of suspected diagnosis!{" "}
                  <p className="m-0">1-800-866-2759 or 303-370-9395 evenings/weekends.</p>
                </p>
              </Grow>
            </Box>
          </div>
        </div>
        <Grid className="pt-3" container spacing={2}>
          <Grid item xs={6}>
            <RHFMultiSelect
              name="event.condition_id"
              fullWidth
              register={props.register}
              readOnly={props.readonly}
              addBlankOption={true}
              control={props.control}
              label="Condition *"
              options={conditions}
              onChange={conditionChange}
              getOptionLabel={(o) => o?.name || ""}
              getOptionSelected={(o) => o?.name || ""}
              error={!!props.errors.event?.condition_id}
              helperText={props.errors.event?.condition_id && props.errors.event.condition_id.message}
              objGOS={true}
              inputProps={{ "data-testid": "event.condition_id" }}
            />
          </Grid>
          <Grid item xs={6}>
            <RHFMultiSelect
              name="event.facility_visit_type_id"
              fullWidth
              register={props.register}
              readOnly={props.readonly}
              addBlankOption={true}
              getOptionLabel={(o) => o?.name || ""}
              getOptionSelected={(o) => o?.name || ""}
              label="Hospitalization Status"
              control={props.control}
              options={facility_visit_types}
              error={!!props.errors?.event?.facility_visit_type_id}
              helperText={props.errors?.event?.facility_visit_type_id && props.errors.event.facility_visit_type_id.message}
              objGOS={true}
              inputProps={{ "data-testid": "event.facility_visit_type_id" }}
            />
          </Grid>
        </Grid>
        <Grid className="pt-3" container spacing={2}>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              variant="standard"
              name="event.admitted_date"
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
              InputLabelProps={{ shrink: true }}
              inputProps={{ "data-testid": "event.admitted_date" }}
              label="Admitted Date"
              error={!!props.errors.event?.admitted_date}
              helperText={props.errors.event?.admitted_date && props.errors.event.admitted_date.message}
              {...props.register("event.admitted_date")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              variant="standard"
              name="event.discharged_date"
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
              InputLabelProps={{ shrink: true }}
              inputProps={{ "data-testid": "event.discharged_date" }}
              label="Discharged Date"
              error={!!props.errors.event?.discharged_date}
              helperText={props.errors.event?.discharged_date && props.errors.event.discharged_date.message}
              {...props.register("event.discharged_date")}
            />
          </Grid>
          <Grid item xs={6}>
            <RHFMultiSelect
              name="event.pregnant"
              fullWidth
              register={props.register}
              readOnly={props.readonly}
              addBlankOption={true}
              label="Pregnant?"
              disabled={pregnantDisabled}
              control={props.control}
              options={pregnantTypes}
              error={!!props.errors.event?.pregnant}
              helperText={props.errors.event?.pregnant && props.errors.event.pregnant.message}
              objGOS={true}
              inputProps={{ "data-testid": "event.pregnant" }}
            />
          </Grid>
        </Grid>
        <Grid className="pt-3" container spacing={2}>
          <Grid item xs={6}>
            {treatmentAllowed ? (
              treatmentStatus
            ) : (
              <Tooltip title="Treatment is not enabled for the condition selected" placement="right">
                <span>{treatmentStatus}</span>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        {treatmentFields}
        {!props.caseId ? (
          <Grid className="pt-3" container spacing={2}>
            <Grid item xs={12}>
              {treatmentCount < 5 && (
                <Button disabled={(!treatmentAllowed || !treatment) && !props.caseId} startIcon={<AddBox color="primary" />} onClick={addTreatment}>
                  Add new treatment
                </Button>
              )}
            </Grid>
          </Grid>
        ) : null}
      </CardContent>
    </Card>
  );
}
DiseaseInformation.propTypes = {
  control: PropTypes.object,
  readonly: PropTypes.bool,
  register: PropTypes.func,
  caseId: PropTypes.string,
  errors: PropTypes.shape({
    specimen_sent: PropTypes.object,
    specimen_collection_date: PropTypes.object,
    specimen_source_id: PropTypes.object,
    facility_visit_type_id: PropTypes.object,
    condition_id: PropTypes.object,
    lab_type_id: PropTypes.object,
  }),
};

import React from "react";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AddBox, Delete } from "@mui/icons-material";

import RHFMultiSelect from "./RHFMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { setTestResultCount } from "../store/features/conditionSlice";

export default function TestResultsInformation(props) {
  const { testTypes, testResultCount } = useSelector((state) => state.condition);
  const { test_results } = useSelector((state) => state.caseDropdown.caseDropdown);
  const dispatch = useDispatch();

  const addTestResult = () => {
    dispatch(setTestResultCount(testResultCount + 1));
  };

  const deleteTestResult = (test) => {
    dispatch(setTestResultCount(testResultCount - 1));
    const values = props.getValues();
    const test_results = values.lab_test.lab_test_results;
    test_results.splice(test, 1);
    props.setValue("lab_test.lab_test_results", test_results);
  };

  const trHeader = (
    <>
      <Grid className="pt-3" container spacing={2}>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <TextField
              name="lab_test.test_date"
              className="w-100"
              inputProps={{ readOnly: !!props.caseId, max: "9999-12-31" }}
              InputLabelProps={{ shrink: true }}
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
              label="Test Date *"
              variant="standard"
              {...props.register("lab_test.test_date")}
              error={!!props.errors.lab_test?.test_date}
              helperText={props.errors.lab_test?.test_date && props.errors.lab_test?.test_date.message}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="Accession Number"
            variant="standard"
            className="w-100"
            {...props.register("lab_test.accession_number")}
            InputLabelProps={{ shrink: props.watch("lab_test.accession_number") !== "" }}
            inputProps={{ "data-testid": "lab_test.accession_number", readOnly: props.readonly }}
            error={!!props.errors.lab_test?.accession_number}
            helperText={props.errors.lab_test?.accession_number && props.errors.lab_test.accession_number.message}
          />
        </Grid>
      </Grid>
    </>
  );

  const trFields = [];
  const gos = (a, b) => {
    return true;
    // (a === '' && (b instanceof Array && b.length === 0)) ||
    // (a.id && b.id && a.id === b.id && a.name === b.name)
  };

  for (let ix = 0; ix < testResultCount; ix++) {
    trFields.push(
      <Grid className="pt-3" container spacing={2} key={`test-result-number-${ix}`}>
        <Grid item xs={3}>
          <RHFMultiSelect
            name={`lab_test.lab_test_results[${ix}].test_type_id`}
            fullWidth
            register={props.register}
            readOnly={!!props.caseId}
            addBlankOption={true}
            label="Test Type *"
            control={props.control}
            options={testTypes}
            //defaultValue={null}
            //objGOS={true}
            getOptionSelected={gos}
            error={!!props.errors.lab_test?.lab_test_results?.[ix]?.test_type_id}
            helperText={
              props.errors.lab_test?.lab_test_results?.[ix]?.test_type_id && props.errors.lab_test?.lab_test_results?.[ix]?.test_type_id.message
            }
          />
        </Grid>
        <Grid item xs={3}>
          <RHFMultiSelect
            name={`lab_test.lab_test_results[${ix}].test_result_id`}
            fullWidth
            register={props.register}
            readOnly={!!props.caseId}
            addBlankOption={true}
            label="Test Result *"
            control={props.control}
            options={test_results}
            //defaultValue={null}
            //objGOS={true}
            getOptionSelected={gos}
            error={!!props.errors.lab_test?.lab_test_results?.[ix]?.test_result_id}
            helperText={
              props.errors.lab_test?.lab_test_results?.[ix]?.test_result_id && props.errors.lab_test?.lab_test_results?.[ix]?.test_result_id.message
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name={`lab_test.lab_test_results[${ix}].result_value`}
            label="Result Value"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            {...props.register(`lab_test.lab_test_results[${ix}].result_value`)}
            readOnly={!!props.caseId}
            error={!!props.errors.lab_test?.lab_test_results?.[ix]?.result_value}
            helperText={
              props.errors.lab_test?.lab_test_results?.[ix]?.result_value && props.errors.lab_test?.lab_test_results?.[ix]?.result_value.message
            }
          />
        </Grid>
        {ix > 0 && !props.caseId ? (
          <Grid item xs={2}>
            <IconButton onClick={(e) => deleteTestResult(ix)} size="large">
              <Delete />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    );
  }

  return (
    <>
      {trHeader}
      {trFields}
      {!props.caseId ? (
        <Grid className="pt-3" container spacing={2}>
          <Grid item xs={12}>
            {testResultCount < 3 && (
              <Button startIcon={<AddBox color="primary" />} onClick={addTestResult}>
                Add additional test result for this specimen
              </Button>
            )}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
TestResultsInformation.propTypes = {
  watch: PropTypes.func,
  readonly: PropTypes.bool,
  control: PropTypes.object,
  register: PropTypes.func,
  caseId: PropTypes.string,
  errors: PropTypes.shape({
    is_test_resulted: PropTypes.object,
    lab_test: PropTypes.shape({
      lab: PropTypes.object,
      test_date: PropTypes.object,
      lab_test_results: PropTypes.arrayOf(
        PropTypes.shape({
          test_type_id: PropTypes.object,
          test_result_id: PropTypes.object,
        })
      ),
    }),
  }),
};

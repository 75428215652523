import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  limit: 25,
  count: 0,
  page: 0,
  options: {
    facilities: [],
    counties: [],
    conditions: [],
  },
  params: {
    lastName: "",
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    selectedConditions: [],
    selectedFacilities: [],
    selectedCounties: [],
  },
  cases: [],
  thisCase: {},
  error: false,
  errorMessage: "",
  caseLoading: false,
};

const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    setPage(state, action) {
      state.page = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setSearchOptions(state, action) {
      state.options = action.payload;
    },
    setSearchParams(state, action) {
      state.params = action.payload;
    },
    setCases(state, action) {
      state.cases = action.payload;
    },
    setThisCase(state, action) {
      state.thisCase = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    setCaseLoading(state, action) {
      state.caseLoading = action.payload;
    },
  },
});

export const { setPage, setCount, setSearchParams, setCases, setSearchOptions, setThisCase, setError, setErrorMessage, setCaseLoading } =
  caseSlice.actions;

export default caseSlice.reducer;

import React from "react";
import { useSelector } from "react-redux";

export default function Instructions() {
  const { config } = useSelector((state) => state.config);
  const relativeUri = process.env.RELATIVE_URI;
  const userGuide = relativeUri + `assets/${config.instructions.portalGuide}`;
  return (
    <div className="text-center">
      <b>
        Instructions to use Portal :{" "}
        <a rel="noreferrer" href={userGuide} target="_blank">
          Online Reporting Portal User Guide
        </a>
      </b>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../lib/axios";
import { API_URL } from "../components/utils/url";
import { formatPhone } from "../lib/misc";
import {
  setLabExpanded,
  setLabReportRequired,
  setNoLabChecked,
  setNotifiable,
  setOrderExpanded,
  setTestResultCount,
  setTreatmentAllowed,
  setTreatmentCount,
} from "../store/features/conditionSlice";
import { setCaseLoading, setError, setErrorMessage, setThisCase } from "../store/features/caseSlice";
import { constructFormValue } from "../helpers/formHelper";
import { setSelectedRaces } from "../store/features/patientSlice";

export const useCaseInfo = (id, reset) => {
  const [record, setRecord] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      getCase();
    }
  }, [id]);

  const getCase = async () => {
    try {
      dispatch(setCaseLoading(true));
      let caseInfo = await api.get(API_URL.GET_CASE(id));
      dispatch(setThisCase(caseInfo.data.data));
      console.log(caseInfo.data);
      if (caseInfo.data.status == "forbidden") {
        dispatch(setError(true));
        dispatch(setErrorMessage(caseInfo?.data?.message));
        return;
      } else {
        dispatch(setError(false));
        dispatch(setErrorMessage(""));
      }
      if (caseInfo.data.data) {
        caseInfo = caseInfo.data.data;
        caseInfo.event.condition.is_notifiable ? dispatch(setNotifiable(true)) : dispatch(setNotifiable(false));
        caseInfo.event.condition.is_treatment_allowed ? dispatch(setTreatmentAllowed(true)) : dispatch(setTreatmentAllowed(false));
        caseInfo.event.condition.is_lab_report_required ? dispatch(setLabReportRequired(true)) : dispatch(setLabReportRequired(false));
        dispatch(setTreatmentCount(caseInfo?.event?.treatments?.length));
        dispatch(setTestResultCount(caseInfo?.lab_test_results?.length));
        if (caseInfo.lab_test.lab.name === "No Lab Reported") {
          dispatch(setNoLabChecked(true));
          dispatch(setOrderExpanded(false));
          dispatch(setLabExpanded(false));
        } else {
          dispatch(setNoLabChecked(false));
          dispatch(setOrderExpanded(true));
          dispatch(setLabExpanded(true));
        }
        const formValue = constructFormValue(caseInfo);
        dispatch(setSelectedRaces(caseInfo?.patient?.races.map((obj) => obj.name)));
        reset(formValue);
      }
      dispatch(setCaseLoading(false));
    } catch (e) {
      dispatch(setCaseLoading(false));
      dispatch(setError(true));
      dispatch(setErrorMessage("The requested case does not exist."));
    }
  };

  return {};
};

import React, { useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCases, setCount, setPage } from "../../store/features/caseSlice";
import { API_URL } from "../utils/url";
import api from "../../lib/axios";
import { constructCaseSearchParams } from "../../helpers/notificationHelper";
import { formatDate } from "../../lib/misc";
import { caseColumns } from "./CaseLink";

export const CasesTable = () => {
  const { currentUser } = useSelector((state) => state.session);
  const { cases, count, page, params } = useSelector((state) => state.case);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updatePage = async (page) => {
    const searchParams = constructCaseSearchParams(params, page);
    dispatch(setPage(page));
    const casesRes = await api.get(API_URL.GET_CASES, { searchParams });
    if (casesRes) {
      dispatch(setCases(casesRes.data.notifications));
      dispatch(setCount(casesRes.data.total_entries));
    }
  };

  return (
    <div className="patientNotifications m-3 mb-5">
      <div className="text-center pt-3">
        <p className="m-0 card-header">
          PATIENT CASES
          {currentUser && currentUser.permission === "facility" && " - " + currentUser.facilities[0].name}
        </p>
      </div>
      <div className="notificationsTable pt-3">
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            {loading ? (
              // TODO: Add material loading component
              <></>
            ) : (
              cases && (
                <TableContainer component={Paper}>
                  <Table size="medium" stickyHeader>
                    <colgroup>
                      {caseColumns.map((column, index) => (
                        <col key={index} width={column.width} />
                      ))}
                    </colgroup>
                    <TableHead className="tableHeader">
                      <TableRow>
                        {caseColumns.map((column, index) => (
                          <TableCell key={index} align="center">
                            {column.headerName}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cases.map((notification, index) => {
                        return (
                          <TableRow key={index} className="dashboardTableRow">
                            {caseColumns.map((column, index) => {
                              return column?.renderCell ? (
                                <TableCell style={{ padding: "0px" }} align="center" key={index}>
                                  {column.renderCell(notification)}
                                </TableCell>
                              ) : column?.valueFormatter ? (
                                <TableCell style={{ padding: "0px" }} align="center" key={index}>
                                  {formatDate(notification?.[column?.field])}
                                </TableCell>
                              ) : (
                                <TableCell style={{ padding: "0px" }} align="center" key={index}>
                                  {notification[column.field]}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={count}
                          rowsPerPage={25}
                          rowsPerPageOptions={[]}
                          page={page}
                          onPageChange={(e, newPage) => updatePage(newPage)}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
